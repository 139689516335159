import React from 'react';

function Footer() {
  const handleRefresh = () => {
    window.scrollTo(0, 0); 
    window.location.reload();
  };

  return (
    <div className="text-xs mt-4 relative">
      <div className="fixed bottom-10 left-5 right-10 flex justify-between items-center z-10">
        <div className='border border-slate-600 rounded-full shadow-lg shadow-slate-700'>
          <a target='_blank' href="https://wa.me/+919992479216" id="body_contactwa"><strong>
            <img className='rounded-full h-12' loading="lazy" src={require("./logo.png")} alt="Whatsapp to show game on this website" /></strong></a>
        </div>
        <div className='border border-slate-600 bg-black rounded-full shadow-lg shadow-slate-700'>
          <button onClick={handleRefresh} className='rounded-full  w-28 flex justify-center items-center'>
            <img className=' h-full' loading="lazy" src={require("./refreshimage.png")} alt="Refresh button" />
          </button>
        </div>
      </div>

      <p>
        !! DISCLAIMER:- a1satta.live is a non-commercial website. Viewing This Website Is Your Own Risk, All The
        Information Shown On Website Is Sponsored And We Warn You That Matka Gambling/Satta May Be Banned Or Illegal
        In Your Country..., We Are Not Responsible For Any Issues Or Scam..., We Respect All Country Rules/Laws...
        If You Not Agree With Our Site Disclaimer... Please Quit Our Site Right Now. Thank You..
      </p>
      <div>
        <div className="bg-yellow-200 text-sm p-2 text-center"><strong>2018-2023 ©️ a1satta.live All rights reserved </strong></div>
      </div>
    </div>
  );
}

export default Footer;
