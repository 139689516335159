import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DNAME = process.env.REACT_APP_DNAME;

function Home() {
  const [ownerInfo, setOwnerInfo] = useState({
    name: '',
    phoneNo: ''
  });

  const [owners, setOwners] = useState([]);
  const [submit, setSubmit] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const result = await axios.get(`${DNAME}/getowners`);
      if (result && result.data) {
        console.log("result=>", result.data);
        setOwners(result.data);
      } else {
        setOwners([]);
      }
    } catch (error) {
      console.error('Error fetching owners:', error);
      toast.error("Error fetching owners");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post(`${DNAME}/addowner`, ownerInfo);
      if (result) {
        toast.success("Owner added successfully");
        getData();  // Refresh owners list after adding
      } else {
        toast.error("Failed to add owner");
      }
      setOwnerInfo({
        name: '',
        phoneNo: ''
      });
    } catch (error) {
      console.error('Error adding owner:', error);
      toast.error("Error adding owner");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOwnerInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDelete = async (id) => {
    try {
      const result = await axios.delete(`${DNAME}/deleteowner`, { data: { id } });
      if (result) {
        toast.success("Owner deleted successfully");
        getData(); // Refresh owners list after deleting
      } else {
        toast.error("Failed to delete owner");
      }
    } catch (error) {
      console.error('Error deleting owner:', error);
      toast.error("Error deleting owner");
    }
  };

  const handleUpdate = (idx) => {
    setSubmit(false);
    setOwnerInfo(owners[idx]);
  };

  const submitUpdate = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.put(`${DNAME}/updateowner`, ownerInfo);
      if (result) {
        toast.success("Owner updated successfully");
        getData();
        setOwnerInfo({
          name: '',
          phoneNo: ''
        });
        setSubmit(true);
      } else {
        toast.error("Failed to update owner");
      }
    } catch (error) {
      console.error('Error updating owner:', error);
      toast.error("Error updating owner");
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={submit ? handleSubmit : submitUpdate}>
        <div className='flex flex-col md:flex-row my-6 mx-4 md:mx-28 rounded p-4 border border-slate-400 items-end'>
          <div className='w-4/5 mr-6'>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input name='name' value={ownerInfo.name} onChange={handleChange} type="text" id="first_name" className="w-full md:w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
          </div>
          <div className='w-4/5 mr-6'>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone No.</label>
            <input name='phoneNo' value={ownerInfo.phoneNo} onChange={handleChange} type="number" id="phone_no" className=" w-full md:w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
          </div>
          <button type="submit" className="h-12 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{submit ? "Submit" : "Update"}</button>
        </div>
      </form>

      <div className='mx-4 md:mx-28 border border-slate-400 py-4 px-2 w-fit overflow-x-auto'>
        <table className='border-collapse table-auto'>
          <thead>
            <tr>
              <th className='w-56 border border-slate-400'>Name</th>
              <th className='w-36 border border-slate-400'>Phone No.</th>
              <th className='w-56 border border-slate-400'>Action</th>
            </tr>
          </thead>
          <tbody>
            {owners.map((item, idx) => (
              <tr key={idx}>
                <td className='w-56 text-center border border-slate-300'>{item.name}</td>
                <td className='w-36 text-center border border-slate-300'>{item.phoneNo}</td>
                <td className='w-56 text-center border border-slate-300'>
                  <div className='w-full flex justify-evenly'>
                    <div className='hover:text-green-400 hover:cursor-pointer' onClick={() => handleUpdate(idx)}>
                      <i className="fa-regular fa-pen-to-square"></i>
                    </div>
                    <div className='hover:text-red-400 hover:cursor-pointer' onClick={() => handleDelete(item._id)}>
                      <i className="fa-solid fa-trash"></i>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Home;
