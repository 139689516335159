
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import Games from "./components/Games/Games";
import GameResult from "./components/Game Result/GameResult";
import AdManager from "./components/AdManager/AdManager";
import Navbar from "./components/Navbar/Navbar";
import { ToastContainer } from 'react-toastify';
import { useEffect, useState } from "react";
import IstNavbar from "./components/IstNavbar/IstNavbar";
import IstPage from "./components/IstPage/IstPage";
import CustomMonthResult from "./components/CustomMonthResult/CustomMonthResult";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
// import { useNavigate } from "react-router-dom";

function App() {
  const [loggedin, setLoggedin] = useState(false);
  // const navigate = useNavigate();
  const updatelogin = () => {
    setLoggedin(!loggedin);
  }

  const location = useLocation();

   useEffect(()=>{
    const logged=localStorage.getItem('loggedIn')
    if(logged){
      setLoggedin(true)
    }
    else{
      setLoggedin(false);
    }
   },[])

  return (
    <>
      {/* <BrowserRouter> */}
        <ToastContainer />
        {!location.pathname.startsWith("/admin") && <IstNavbar />}
       
        <Routes>
          <Route path="/" element={<IstPage />} />
          <Route path="/custommonthlychart" element={<CustomMonthResult/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/admin" element={<Login update={updatelogin} />} />
          {loggedin && (
            <Route path="/admin/*" element={<AdminLayout update={updatelogin}/>}>
              <Route path="home" element={<Home />} />
              <Route path="games" element={<Games />} />
              <Route path="gamesresult" element={<GameResult />} />
              <Route path="admanager" element={<AdManager />} />
            </Route>
          )}
        </Routes>
        {!location.pathname.startsWith("/admin") && <Footer />}
      {/* </BrowserRouter> */}
    </>
  );
}

const AdminLayout = ({update}) => {
  return (
    <div>
     <Navbar update={update} />
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="games" element={<Games />} />
        <Route path="gamesresult" element={<GameResult />} />
        <Route path="admanager" element={<AdManager />} />
      </Routes>
    </div>
  );
}

export default App;
