import React from 'react'

function AllGamesTiming({ name, number }) {
    return (
        <div>

            <div className=' m-2'>
                <div class="w-full  border-dashed rounded-3xl bg-yellow-300 border-4 p-2 text-xl border-blue-600 text-center">
                    <p><strong>🙏🏿नमस्कार साथियो 🙏🏿</strong></p>
                    <p><strong>GAME PLAY करने के लिए निचे दिए गये लिंक पर क्लिक करें&nbsp; &nbsp;</strong></p>
                    <p><strong>----{name}----</strong></p>

                    <p><strong>♕♕&nbsp;<span id="kname">{name} online khaiwal </span>&nbsp; ♕♕</strong></p>
                    <p><strong>⏰ तिलक बाज़ार ------------ 12:40 PM</strong></p>
                    <p><strong>⏰ कनाट पैलेस ------------ 01:40 PM</strong></p>
                    <p><strong>⏰ दिल्ली बाज़ार ------------ 02:48 PM</strong></p>
                    <p><strong>⏰ चावला --------------- 03:40 PM</strong></p>
                    <p><strong>⏰ श्री गणेश ------------ 04:20 PM</strong></p>
                    <p><strong>⏰ फ़रीदाबाद ----------- 05:48 PM</strong></p>
                    <p><strong>⏰ लाजपत नगर --------------- 06:40 PM</strong></p>
                    <p><strong>⏰ वसंत विहार --------------- 07:40 PM</strong></p>
                    <p><strong>⏰ गाज़ियाबाद-------------- 08:35 PM</strong></p>
                    <p><strong>⏰ द्वारका दिश ------------- 09:40 PM</strong></p>
                    <p><strong>⏰ गली ------------- 11:30 PM</strong></p>
                    <p><strong>⏰ दिसावर ----------------- 01:00 AM</strong></p>
                    <p>
                        ============================<br />
                        ============================
                    </p>
                    <p>
                        <strong>🤑 Rate list 💸</strong><br />
                        <strong>जोड़ी रेट 10-------970</strong><br />
                        <strong>हरूफ रेट 100-----970</strong>
                    </p>
                    <p>♕♕ &nbsp;<strong id="kname1">{name} online khaiwal </strong>♕♕</p>


                    <div className='h-20 flex justify-center items-center'>
                        <div >
                            <a className='hover:blue-800' target='_blank' href={`https://wa.me/${number}`} id="body_contactwa">
                                <strong> 👉https://wa.me/{number} </strong></a>
                        </div>

                    </div>
                    <div className=' h-20 flex justify-center items-center '>
                        <a target='_blank' href={`https://wa.me/${number}`} id="body_contactwa"><strong>
                            <img className='rounded-full h-16 ' loading="lazy" src={require("./logo.png")} alt="Whatsapp to show game on this website" /></strong></a>
                    </div>

                    <p className='text-sm'>NOTE: &nbsp; इस नंबर पर लीक गेम नही मिलता गेम लेने वाले भाई कॉल या मैसेज न करें।</p>
                </div>
            </div>
        </div>
    )
}

export default AllGamesTiming
